// Fonts
$sans-serif: Noto Sans, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;

// Responsive breaks
$break-extra-small: 575px;
$break-small: 576px;
$break-medium: 768px;
$break-large: 992px;
$break-extra-large: 1200px;

@mixin transition($args...) {
    -webkit-transition: $args;
       -moz-transition: $args;
            transition: $args;
}
